import React from 'react';
import './features.css'
import stream from '../../assets/stream.png'

const Features = () => {
  return (
    <div className='fijuplay__features-parent'>
    <div className='fijuplay__features section__padding' id='features'>
     
     
     <div className='fijuplay__features-content'>
      <h1 className='gradient__text'>Stream Anytime.</h1>
      <p>Available on Android, IoS, Huawei, 
        KaiOs, Apple TV, Web Browsers and more.</p>
     </div>
     
     
     <div className='fijuplay__features-image'>
      <img src={stream} alt="Stream" />
     </div>
      </div>

    <hr width="100%" 
        size="12" 
        align="right" 
        color="#658CBB" noshade/>

      </div>
  )
}

export default Features