import React from 'react'
import './header.css'
import home from '../../assets/home.png'
import AI from '../../assets/AI.png'

const Header = () => {
  return (
    <div className='fijuplay__header-parent'>
    <div className='fijuplay__header section__padding ' id='home'>
      <div className='fijuplay__header-content'>
        <h1 className='gradient__text'>Unlimited Nollywood Movies, TV programmes and much more.</h1>
          <p>Watch anywhere. Cancel at any time.</p>


      <div className='fijuplay__header-content__input'>
      <input type="email"  placeholder='Email Address'/>
      <button type='button'>Get Started</button>
      </div>
      
      
      {/* <div className='fijuplay__header-content__people'>
      <img src={home} alt="home" />
      <p>Trusted by over 10,000 Homes</p>
      </div> */}

      </div>

    </div>

    <hr width="100%" 
        size="12" 
        align="right" 
        color="#658CBB" noshade/>
    </div>
  )
}

export default Header