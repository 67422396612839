import React from 'react';
import './footer.css'
import logoFooter from '../../assets/logo_footer.png'

const Footer = () => {
  return (
    <div className='fijuplay__footer-parent'>
    <div className='fijuplay__footer section__padding'>
    <div className='fijuplay__footer-links'>
    {/* <div className='fijuplay__footer-links_logo'>
      <img src={logoFooter} alt="footer-logo" />
    </div> */}


    <div fijuplay__footer-links_div>
      <u><h4>Useful Links</h4></u>
      <u><p>FAQS</p></u>
      <u><p>Social Media</p></u>
      <u><p>Media Centre</p></u>
      <u><p>Contact</p></u>
    </div>

    <div fijuplay__footer-links_div>
      <u><h4>Company</h4></u>
      <u><p>Investor Relations</p></u>
      <u><p>Terms of Use</p></u>
      <u><p>Corporate Information</p></u>
      <u><p>Legal Guarantee</p></u>
    </div>

    <div fijuplay__footer-links_div>
      <u><h4>Account</h4></u>
      <u><p>Privacy Policy</p></u>
      <u><p>Help Centre</p></u>
      <u><p>Get in touch</p></u>
      <u><p>info@fijuplay.com</p></u>
    </div>

    </div>

      <div className='fijuplay__footer-copyright'>
        <p>© fijuplay Technologies. All rights reserved</p>
      </div>


    </div>
      </div>
  )
}

export default Footer