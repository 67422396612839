import React from 'react'
import questions from './faqs.json'
import Banner from './banner'
import './faqs.css'

const FAQS = () => {
  return (
    <div>
    <div className='fijuplay__faqs-parent'>
    <Banner>
    <Banner.Header>Frequently Asked Questions</Banner.Header>
    {questions.map((question) => (
      <Banner.Entity key={question.id}>
        <Banner.Question>{question.question}</Banner.Question>
        <Banner.Text>{question.answer}</Banner.Text>
      </Banner.Entity>
    ))}
  </Banner>
  <hr width="100%" 
        size="12" 
        align="right" 
        color="#658CBB" noshade/>
  </div>
  </div>
  )
}

export default FAQS