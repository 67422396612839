import React from 'react'
import './experience.css'
import experience from '../../assets/experience.png'

const Experiences = () => {
  return (
    <div className='fijuplay__experience-parent'>
    <div className='fijuplay__experience section__padding' id='features'>
     
     
     <div className='fijuplay__experience-content'>
      <h1 className='gradient__text'>Share your experience.</h1>
      <p>Tell your friends and family. Stream unlimited nollywood movies on your phone, tablet, laptop and TV without paying more.</p>
     </div>
     
     
     <div className='fijuplay__experience-image'>
      <img src={experience} alt="experience" />
     </div>
      </div>

    <hr width="100%" 
        size="12" 
        align="right" 
        color="#658CBB" noshade/>

      </div>
  )
}

export default Experiences