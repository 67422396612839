import React, { useState, useEffect, Fragment } from 'react';
import './App.css'
import DotLoader from "react-spinners/DotLoader";
import { Navbar } from './components';
import { Header, Footer, Features, Categories, CTA, FAQS, Experiences} from './containers'

function App (){

  const [loading, setLoading] = useState(false);
   
  useEffect(() => {
    setLoading(true) 
    setTimeout(() => {
      setLoading(false)
    }, 4000)
  }, [])

  return (
    <div className='App'>
      <div className='gradient__bg'>
    {
      loading ?
// React spinner
      <section className='loader'>
      <DotLoader
      color={'#111b68'}
      loading={loading}
      size={45}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
    </section>
      :
      <>
      <Navbar/>
      <Header/>
      <Features/>
      <Categories/>
      <Experiences/>
      <FAQS/>
      <CTA/>
      <Footer/>
      </>
      
}
</div>
</div>
  )
}

export default App;