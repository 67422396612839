import React from 'react'
import './cta.css'

const CTA = () => {
  return (
    <div className='fijuplay__cta-parent'>
     <div className="fijuplay__cta">
    <div className="fijuplay__cta-content">
      <p>Ready to watch?</p>
      <h3>Register today & start exploring the endless possibilities.</h3>
    </div>
    <div className="fijuplay__cta-btn">
      <button type="button">Get Started</button>
    </div>
  </div>
        <hr width="100%" 
        size="12" 
        align="center" 
        color="#658CBB" noshade/>
    </div>
  )
}

export default CTA