import styled from "styled-components/macro";

export const Container = styled.div`
  border-bottom: 9px solid #040C18;
  display: flex;
`;
export const Entity = styled.div`
  color: #ffffff;
  border: 1px solid #040C18;
  max-width: 900px;
  width: 700px;
  margin-bottom: 10px;
  margin: auto;
  flex-direction: column;
  &:first-of-type {
    margin-top: 1.5em;

}

@media (max-width: 900px) {
  max-width: 900px;
  width: 100%;
  flex-direction: column;
}


@media (max-width: 700px) {
  max-width: 900px;
  width: 100%;
  flex-direction: column;
}

}
@media (max-width: 650px) {
  max-width: 900px;
  width: 100%;
  flex-direction: column;
}


  }
  @media (max-width: 550px) {
    max-width: 900px;
    width: 130%;
    margin-left: -12%;
    flex-direction: column;
  
  }
`;
export const Inner = styled.div`
  padding: 75px 80px;
  max-width: 800px;
  margin: auto;
  flex-direction: column;
  display: flex;
`;
export const Question = styled.div`
  font: 25px;
  font-size: 20px;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 2px;
  display: flex;
  font-weight: 500;
  background: #658CBB;
  padding: 0.75em 1.12em;
  align-items: center;
`;
export const Text = styled.p`
  max-height: 1190px;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  background: #658CBB;
  transition: max-height 0.23s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0.9em 2.1em 0.7em 1.4em;
  user-select: none;
  white-space: pre-wrap;

  @media (max-width: 700px) {
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: 650px) {
    font-size: 16px;
    line-height: 25px;
  }

  @media (max-width: 550px) {
    font-size: 16px;
    line-height: 25px;
  }
`;
export const Header = styled.h1`
  color: #ffffff;
  line-height: 1.5;
  margin-top: 0 !important;
  font-size: 48px;
  margin-bottom: 0.5px;
  margin-left: 8%;
  text-align: center;


  @media (max-width: 700px) {
    font-size: 32px;
    line-height: 1.5;
  }

  @media (max-width: 650px) {
    font-size: 28px;
    line-height: 1.5;
  }


  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 1.5;
  }

  color: #ffffff;
`;


