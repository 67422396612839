import React from 'react';
import logo from '../../assets/logo.png'
import './navbar.css'

//class naming convention-> BEM: Block Element Modifier

const Navbar = () => {
  return (
    <div className='fijuplay__navbar'>
      <div className='fijuplay__navbar-links'>
        <div className='fijuplay__navbar-links_logo'>
          <img src={logo} alt="fijuplay-logo" />
        </div>
        <div className='fijuplay__navbar-links_container'>
          {/* <p><a href="#getstarted"></a>Get Started</p>
          <p><a href=""></a>Demo</p> */}
        </div>
      </div>
      <div className='fijuplay__navbar-sign'>
        <button type='button'><a href=""></a>Sign in</button>
      </div>
    </div>
  )
}

export default Navbar