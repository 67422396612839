import React from 'react'
import './categories.css'
import categories from '../../assets/categories.png'

const Categories = () => {
  return (
        <div className='fijuplay__categories-parent'>
        <div className='fijuplay__categories section__padding' id='features'>
         
         <div className='fijuplay__categories-image'>
          <img src={categories} alt="categories" />
         </div>
         
         <div className='fijuplay__categories-content'>
          <h1 className='gradient__text'>Browse Categories.</h1>
          <p>Access unlimited categories of videos and quick search 
            to any favourite movies from home </p>
         </div>
         
         
          </div>
    
        <hr width="100%" 
            size="12" 
            align="right" 
            color="#658CBB" noshade/>
    
          </div>
  )
}

export default Categories